// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-size: cover;
  margin: 0;
}
.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 1) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgba(49, 49, 177, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2CAA2C;EAC3C,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,4BAA4B;EAC5B;;;;;GAKC;AACH;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["body{\n  color: #fff;\n  font-family: \"Roboto Condensed\", sans-serif;\n  background-size: cover;\n  margin: 0;\n}\n.App {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  text-align: center;\n  background: rgb(49, 49, 177);\n  background: linear-gradient(\n    0deg,\n    rgba(49, 49, 177, 1) 0%,\n    rgba(49, 49, 177, 1) 50%,\n    rgba(49, 49, 177, 0.6) 100%\n  );\n}\n\n.container {\n  width: 100%;\n  margin: 15% auto;\n}\n\nh1 {\n  font-size: 38px;\n  text-transform: uppercase;\n  line-height: 1;\n}\n\n@media (min-width: 768px) {\n  .container {\n    width: 1100px;\n  }\n  h1 {\n    font-size: 58px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
