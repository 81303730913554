import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          We are in-progress!! <br />
          Coming soon!!
        </h1>
      </div>
    </div>
  );
}

export default App;
